import React, { useState, useRef, useEffect, useCallback } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { getToken, getUserId } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import { format, isToday, isYesterday  } from 'date-fns';

function ChatAI() {
  const [allThreadMessages, setAllThreadMessages] = useState([]);
  const [currentUserMessageText, setCurrentUserMessage] = useState('');
  const [isNewMessageLoading, setIsNewMessageLoading] = useState(false);
  const [isPreviousMessagesLoading, setIsPreviousMessagesLoading] = useState(false);
  const [typingUsers, setTypingUsers] = useState(new Set());
  const [typingUsersNames, setTypingUsersNames] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const listRef = useRef(null);
  const messagesEndRef = useRef(null);

  const [ws, setWs] = useState(null);
  const TYPING_TIMEOUT = 3000; 
 
  const token = getToken();
  const threadId = localStorage.getItem('threadId');
  const userId = getUserId();

  // WEB SOCKET CONNECTION
  useEffect(() => { 
    let ws = null;
    let pingInterval = null;
    let reconnectAttempt = 0;
    let reconnectTimeout = null;
    const maxReconnectDelay = 30000; // Maximum delay between reconnection attempts (30 seconds)
    let isComponentMounted = true;
  
    const connect = () => {
      const websocketProtocol = BASE_URL.startsWith('https') ? 'wss://' : 'ws://';
      const websocketHost = BASE_URL.replace(/https?:\/\//, '');
      const websocketURL = `${websocketProtocol}${websocketHost}/ws`;
  
      ws = new WebSocket(websocketURL);
  
      ws.onopen = () => {
        console.log('WebSocket Connected');
        if (threadId) {
          console.log(`Joining thread ${threadId} with userId ${userId}`);
          ws.send(JSON.stringify({ type: 'join_thread', threadId, userId }));
        }
  
        // Set up ping interval
        pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: 'ping' }));
          }
        }, 30000); // Send a ping every 30 seconds
      }; 
  
      ws.onmessage = (event) => {
        console.log('Message from server: ', event.data);
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'incoming_assistant_message') {
            setAllThreadMessages(prevMessages => {
              const filteredMessages = prevMessages.filter(msg => !msg.temporary);
              const newMessages = data.messages.map(msg => ({
                role: msg.role,
                content: [{ type: 'text', text: { value: msg.content } }],
                user: msg.role === 'assistant' ? 'AI' : 'You',
                created_at: msg.created_at,
                user_id: msg.role === 'assistant' ? null : msg.user_id,
                id: msg.id || `${Date.now()}-${Math.random().toString(36)}`,
                first_name: msg.first_name || null,  
                last_name: msg.last_name || null,   
              }));
              // console.log({newMessages}); // debug
              setIsNewMessageLoading(false);
              return [...filteredMessages, ...newMessages];
            });
          } else if (data.type === 'user_joined') {
            if (data.userId && data.userId !== userId) { // Check if the joined user is not the current user
              fetchName(data.userId).then(userInfo => {
                if (userInfo) {
                  const userName = `${userInfo.first_name} ${userInfo.last_name.charAt(0)}`;
                  toast.info(`${userName} has joined the chat`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 2000,
                  });
                }
              });
            }
          } else if (data.type === 'user_typing') {
            console.log('USER_TYPING TRIGGERED:', data);
            if (data.userId && data.userId !== userId) {
              console.log('inside if')
              setTypingUsers(prevTypingUsers => {
                const newTypingUsers = new Set(prevTypingUsers);
                if (data.isTyping) {
                  newTypingUsers.add(data.userId);
                } else {
                  newTypingUsers.delete(data.userId);
                }
                return newTypingUsers;
              });
            }
          } else if (data.type === 'error') {
            console.error('Error from server:', data.message);
            // Handle error...
          } else if (data.type === 'pong') {
            // Received pong from server, connection is alive
          } else {
            console.log('Received unknown message type:', data.type);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
  
      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        // Handle error...
      };
  
      ws.onclose = (event) => {
        console.log('WebSocket Closed:', event);
        clearInterval(pingInterval);
  
        if (isComponentMounted) {
          const delay = Math.min(1000 * (2 ** reconnectAttempt), maxReconnectDelay);
          console.log(`Reconnecting in ${delay}ms...`);
          reconnectTimeout = setTimeout(() => {
            reconnectAttempt++;
            connect();
          }, delay);
        }
      };

      setWs(ws);
    };
  
    connect();
  
    return () => {
      isComponentMounted = false;
      if (ws) {
        ws.close();
      }
      clearInterval(pingInterval);
      clearTimeout(reconnectTimeout);
    };

  }, [threadId, BASE_URL]);

  const handleSendMessage = async () => {
    const userMessageText = currentUserMessageText;
    setCurrentUserMessage('');
    if (userMessageText.trim()) {
      setIsNewMessageLoading(true); 

      const userInfo = await fetchName(userId);
      setAllThreadMessages(prevMessages => [
        ...prevMessages,
        { 
          role: 'user', 
          content: [{ type: 'text', text: { value: userMessageText } }], 
          user: 'You', 
          created_at: Math.floor(Date.now() / 1000),
          user_id: userId,
          first_name: userInfo?.first_name,
          last_name: userInfo?.last_name,
          temporary: true,  // Attach temporary flag
          id: `temp-${Date.now()}` // temp id
        }
      ]);

      let updatedThreadId = threadId;

      if (threadId === 'undefined' || !threadId) {
        try {
          const response = await fetch(`${BASE_URL}/api/openai/createthread`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ prompt: userMessageText, user_id: userId }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const responseData = await response.json();
          updatedThreadId = responseData.threadId;
          localStorage.setItem('threadId', updatedThreadId);
  
          // Join the new thread via WebSocket
          if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ 
              type: 'join_thread', 
              threadId: updatedThreadId,
              userId: userId 
            }));
          }
        } catch (error) {
          console.error('Error:', error);
          setIsNewMessageLoading(false);
          setAllThreadMessages(prevMessages => [...prevMessages, { text: `Error: ${error.message}`, user: 'AI' }]);
          return;
        }
      }
  
      // Send message via WebSocket
      if (ws && ws.readyState === WebSocket.OPEN) {
        console.log('Sending message via web socket in handleSendMessage', userMessageText); // debug
        ws.send(JSON.stringify({
          type: 'new_user_message',
          userId: userId,
          content: userMessageText,
          threadId: updatedThreadId,
          timestamp: Math.floor(Date.now() / 1000)
        }));
      } else {
        console.error('WebSocket is not open');

        // Fallback to HTTP if WebSocket is not available
        try {
          console.log('Websocket not working, sending message via HTTP in handleSendMessage'); 
          const messageResponse = await fetch(`${BASE_URL}/api/openai/createmessage`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ threadId: updatedThreadId, prompt: userMessageText, user_id: userId }),
          });
  
          if (!messageResponse.ok) {
            throw new Error(`HTTP error! Status: ${messageResponse.status}`);
          }
  
          const apiResponseData = await messageResponse.json();
          const assistantResponse = apiResponseData.assistantResponse; 
          const assistantResponseText = assistantResponse[0].text.value;
  
          setAllThreadMessages(prevMessages => [
            ...prevMessages,
            { 
              role: 'assistant', 
              content: [{ type: 'text', text: { value: assistantResponseText } }], 
              user: 'AI', 
              created_at: Math.floor(Date.now() / 1000),
              user_id: null 
            }
          ]);
        } catch (error) {
          console.error('Error:', error);
          setAllThreadMessages(prevMessages => [
            ...prevMessages,
            { role: 'assistant', 
            content: [{ type: 'text', text: { value: `Error: ${error.message}` } }], 
            user: 'AI', created_at: Math.floor(Date.now() / 1000),
            user_id: null }
          ]);
        }
      }
    }
  };

  // only being used for initial render
  const fetchInitialMessages = async (threadId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/openai/fetchmessages/${threadId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const fetchedMessagesData = await response.json();
      console.log({fetchedMessagesData}); // debug

      // Sort messages by timestamp, oldest first
      const sortedMessages = fetchedMessagesData.messages.sort((a, b) => a.created_at - b.created_at);
      setAllThreadMessages(sortedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const fetchOlderMessages = async (threadId, limit = 20, before = null) => {
    try {
      let url = `${BASE_URL}/api/openai/fetchmessages/${threadId}?limit=${limit}`;
      if (before) {
        url += `&before=${before}`;
      }
  
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      const fetchedMessagesData = await response.json();
      console.log({fetchedMessagesData}); // debug
  
      // Sort messages by timestamp, oldest first
      return fetchedMessagesData.messages.sort((a, b) => a.created_at - b.created_at);
    } catch (error) {
      console.error('Error fetching older messages:', error);
      return [];
    }
  };

  const fetchThreads = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/openai/threads`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      if (data.threads.length > 0) {
        // const latestThreadId = data.threads[0].chatbot_thread_id;
        const latestThreadId = 'thread_ngls6v72Ilm51M8c4i2v8X4B'; // hackkk
        localStorage.setItem('threadId', latestThreadId);
        fetchInitialMessages(latestThreadId);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };

  const fetchName = useCallback(async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/readuser/${userId}`, {
        method: 'GET',
        headers: { 
          'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) throw new Error('Failed to fetch user info');
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return null;
    }
  }, [BASE_URL, token]);

  useEffect(() => {
    fetchThreads();
  }, []);

  const handleMessageChange = (event) => {
    setCurrentUserMessage(event.target.value);

    // Clear any existing timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // set typing status to true
    sendTypingStatus(event.target.value.length > 0);

    // Set a new timeout
    const newTimeout = setTimeout(() => {
      sendTypingStatus(false);
    }, TYPING_TIMEOUT);

    setTypingTimeout(newTimeout);
  };

  const sendTypingStatus = (isTyping) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        type: 'user_typing',
        threadId,
        userId,
        isTyping
      }));
    }
  };

  useEffect(() => {
    if (currentUserMessageText === '') {
      sendTypingStatus(false);
    }
  }, [currentUserMessageText]); 

  const getTypingUsersNames = useCallback(async () => {
    const typingUsersArray = Array.from(typingUsers);
    
    const names = await Promise.all(typingUsersArray.map(async (userId) => {
      const userInfo = await fetchName(userId);
      return userInfo ? `${userInfo.first_name} ${userInfo.last_name.charAt(0)}` : 'Unknown User';
    }));
    
    return names;
  }, [typingUsers, fetchName]);

  useEffect(() => {
    const updateTypingUsers = async () => {
      try {
        const names = await getTypingUsersNames();
        setTypingUsersNames(names);
      } catch (error) {
        console.error('Error updating typing users:', error);
      }
    };
    
    updateTypingUsers();
  }, [typingUsers, getTypingUsersNames]);

  const handleBlur = () => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    sendTypingStatus(false);
  };

  const formatNameAndTime = (firstName, lastName, timestamp, isAssistant) => {
    if (!timestamp) {
      return (
        <span style={{ fontWeight: 'bold' }}>
          {firstName} (Invalid Time):
        </span>
      );
    }
  
    const date = new Date(timestamp * 1000);
    const formattedDate = format(date, 'MMMM d, yyyy, hh:mm a');
    const formattedName = isAssistant ? firstName : (lastName ? `${firstName}` : firstName);
  
    return (
      <span style={{ fontWeight: 'bold' }}>
        {formattedName} ({formattedDate}):
      </span>
    );
  };

  const formatDate = (timestamp) => {
    if (!timestamp) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    const date = new Date(timestamp * 1000);
    if (isToday(date)) {
      console.log({ isToday }); // debug
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else {
      return format(date, 'MMMM d, yyyy');
    }
  };

  const scrollToBottom = () => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    console.log('allThreadMessages updated:', allThreadMessages)
  }, [allThreadMessages]);

  const loadMoreMessages = useCallback(() => {
    if (isPreviousMessagesLoading || !hasMore) return;
   
    setIsPreviousMessagesLoading(true);
    
    setAllThreadMessages(prevMessages => {
      // console.log('Current messages in loadMoreMessages:', prevMessages); // debug
      const oldestMessage = prevMessages[0];
      console.log('Oldest message:', oldestMessage); // debug --> correctly fetching 
  
      if (!oldestMessage) {
        console.error('No oldest message found'); 
        setIsPreviousMessagesLoading(false);
        return prevMessages;
      }
  
      fetchOlderMessages(threadId, 20, oldestMessage.created_at)
        .then(newMessages => {
          // console.log('New messages fetched:', newMessages); // debug
          if (newMessages.length < 20) {
            setHasMore(false);
          }
          setAllThreadMessages(prev => {
            const updatedMessages = [...newMessages, ...prev];
            // console.log('Updated messages:', updatedMessages); // debug
            return updatedMessages;
          });
        })
        .catch(error => {
          console.error('Error fetching older messages:', error);
        })
        .finally(() => {
          setIsPreviousMessagesLoading(false);
        });
  
      return prevMessages; // Return the current state unchanged for this update
    });
  }, [isPreviousMessagesLoading, hasMore, threadId, fetchOlderMessages]);

  // const loadMoreMessages = useCallback(async () => {
  //   if (isPreviousMessagesLoading || !hasMore) return;
  
  //   setIsPreviousMessagesLoading(true);
  
  //   try {
  //     const oldestMessage = allThreadMessages[0];
  //     console.log({allThreadMessages}); // debug --> empty
  //     console.log('Oldest message:', oldestMessage);
  
  //     if (!oldestMessage) {
  //       console.error('No oldest message found');
  //       return;
  //     }
  
  //     const newMessages = await fetchOlderMessages(threadId, 20, oldestMessage.created_at);
  //     console.log('New messages fetched:', newMessages);
  
  //     if (newMessages.length < 20) {
  //       setHasMore(false);
  //     }
  
  //     setAllThreadMessages(prevMessages => [...newMessages, ...prevMessages]);
  //   } catch (error) {
  //     console.error('Error fetching older messages:', error);
  //   } finally {
  //     setIsPreviousMessagesLoading(false);
  //   }
  // }, [isPreviousMessagesLoading, hasMore, threadId, fetchOlderMessages, allThreadMessages]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop } = listRef.current;
      if (scrollTop === 0 && !isPreviousMessagesLoading && hasMore) {
        console.log('Attempting to load more messages'); // debug
        loadMoreMessages();
      }
    };
  
    const listElement = listRef.current;
    listElement.addEventListener('scroll', handleScroll);
    return () => listElement.removeEventListener('scroll', handleScroll);
  }, [isPreviousMessagesLoading, hasMore]); 

  function stripContextFromMessage(message) {
    const regex = /^\[.*?\]\s*/;
    return message.replace(regex, ' ');
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <CssBaseline />
      <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <Paper elevation={3}
          sx={{
            marginTop: '60px',
            width: '80vh',
            height: '85vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >

          <List ref={listRef} sx={{ overflow: 'auto' }}>
            <Box sx={{ display: 'flex' }}>
              {isPreviousMessagesLoading && <CircularProgress />}
            </Box>
            
            {(() => {
              let currentDate = null;
              return allThreadMessages.map((msg, index) => {
                const messageDate = new Date(msg.created_at * 1000).toDateString();
                let dateDivider = null;

                if (messageDate !== currentDate) {
                  currentDate = messageDate;
                  dateDivider = (
                    <ListItem 
                      key={`divider-${msg.created_at}`}
                      sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        padding: '10px 0' 
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'grey.400' }} />
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            margin: '0 10px', 
                            padding: '2px 10px', 
                            backgroundColor: 'grey.200', 
                            borderRadius: '10px',
                            color: 'text.secondary'
                          }}
                        >
                          {formatDate(msg.created_at)}
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '1px', backgroundColor: 'grey.400' }} />
                      </Box>
                    </ListItem>
                  );
                }

                return (
                  <React.Fragment key={msg.id}>
                    {dateDivider}
                    <ListItem sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Paper
                        sx={{
                          padding: '10px',
                          margin: '5px',
                          backgroundColor: msg.role === 'assistant' ? 'grey.300' : 'grey.100',
                          width: '100%'
                        }}
                        elevation={1}
                      >
                        <div>
                          {msg.role === 'assistant'
                            ? formatNameAndTime('Heartee Inventory Assistant', null, msg.created_at, true)
                            : formatNameAndTime(msg.first_name, msg.last_name, msg.created_at, false)
                          } 
                          {Array.isArray(msg.content) ? 
                            msg.content.map((contentItem, contentIndex) => (
                              <div key={contentIndex} style={{ display: 'inline' }}>
                                {contentItem.type === 'text'
                                  ? <span>{stripContextFromMessage(contentItem.text.value)}</span>
                                  : contentItem.type === 'image_file'
                                    ? <img
                                      src={`${BASE_URL}/api/image/${contentItem.image_file.file_id}`}
                                      alt="Assistant Response"
                                      style={{ maxWidth: '100%', height: 'auto' }}
                                    /> : null
                                }
                              </div>
                            )) : 
                            <span>{stripContextFromMessage(msg.text)}</span>
                          }
                        </div>
                      </Paper>
                    </ListItem>
                  </React.Fragment>
                );
              });
            })()}

            {isNewMessageLoading && <LinearProgress style={{ height: '5px', width: '93%', margin: '20px auto' }} />}
            <div ref={messagesEndRef} />
          </List>

          <Box sx={{ padding: '5px 10px', minHeight: '20px', color: 'text.secondary' }}>
            {typingUsersNames.length > 0 && (
              <Typography variant="caption">
                {typingUsersNames.join(', ')} {typingUsersNames.length === 1 ? 'is' : 'are'} typing...
              </Typography>
            )}
          </Box>

          <Box
            component="form"
            sx={{
              display: 'flex',
              padding: '10px'
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type your message"
              value={currentUserMessageText}
              onChange={handleMessageChange}
              onBlur={handleBlur}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  handleSendMessage();
                  ev.preventDefault();
                }
              }}
              sx={{ mr: 1 }}
            />

            <Button
              variant="contained"
              onClick={handleSendMessage}
            >
              Send
            </Button>

          </Box>

          {/* <Button 
            onClick={() => {
              localStorage.removeItem('threadId');
              setAllThreadMessages([]);
            }}
          >
            New Chat
          </Button> */}

        </Paper>
      </Container>
    </Box>
  );

}

export default ChatAI;