import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

function RecentActivity() {
  const token = getToken(); 
  
  const [activities, setActivities] = useState([]);
  const [averageYield, setAverageYield] = React.useState([]);

  // Function to fetch recent activities from the backend
  const fetchRecentActivities = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/recentactivity`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
  
      // Transform the data into the desired format if needed
      const transformedData = data.data.map(activity => ({
        action: activity.operation_type,
        source: `${activity.operation_type.charAt(0).toUpperCase() + activity.operation_type.slice(1)} ${activity.activity_type.charAt(0).toUpperCase() + activity.activity_type.slice(1)}`,
        strainName: activity.strain_name,
        timestamp: new Date(activity.timestamp).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }),
        fullName: `${activity.first_name} ${activity.last_name}`,
      }));
  
      setActivities(transformedData);
    } catch (error) {
      console.error('Failed to fetch recent activities:', error);
    }
  };  

  const fetchAverageYields = () => {
    fetch(`${BASE_URL}/api/averageyields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => response.json())
    .then(data => setAverageYield(data))
    .catch(error => console.error('Error:', error));
  }

  // Use the useEffect hook to fetch recent activities when the component mounts
  useEffect(() => {
    fetchRecentActivities();
    fetchAverageYields();
  }, []);

  const getAverageYield = (strainName) => {
    const yieldData = averageYield.find(yieldData => yieldData.strain_name === strainName);
    return yieldData ? parseFloat(yieldData.average_yield_per_block).toFixed(1) : 'N/A';
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          padding: 1.5,
          height: '300px',
          overflowY: 'auto',
          borderRadius: '10px',
          border:'1px solid #d9d9d9'
        }}
      >
        <Typography variant="h6" sx={{ paddingBottom: 1, paddingLeft: 0.6, fontWeight:'500' }}>Recent Activity</Typography>

        <List sx={{ padding: 0, margin: 0 }}>
          {activities.map((activity, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ padding: '14px 0px' }}>
                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1, marginLeft: 0 }}>
                  {activity.action === 'add' && <AddOutlinedIcon sx={{ ml:1, fontSize: '2rem', color: '#00CC00', bgcolor: '#E6FFE6', borderRadius:'50%', p:0.5 }} />}
                  {activity.action === 'edit' && <EditOutlinedIcon sx={{ ml:1, fontSize: '2rem', color: '#2196F3', bgcolor: '#E3F2FD', borderRadius:'50%', p:0.5 }} />}
                  {activity.action === 'delete' && <DeleteOutlined style={{ marginLeft:'7.5px', fontSize: '1.3rem', color: '#FF6666', backgroundColor: '#FCF0F2', borderRadius:'50%', padding:'7px' }} />}
                </ListItemIcon>
                <ListItemText
                  primary={`${activity.source} - ${activity.strainName}`}
                  primaryTypographyProps={{ variant: 'body2', fontFamily: 'Inter', fontWeight: 'bold' }}
                  secondary={`${activity.timestamp} - ${activity.fullName}`}
                />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle1" noWrap>
                    ~{`${getAverageYield(activity.strainName)}`} lbs
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              {index < activities.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </>
  );
}

export default RecentActivity;