import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper'; 
import ReactApexChart from 'react-apexcharts';
import chroma from 'chroma-js';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

function WastePieChart() {
    const [data, setData] = useState([]);
    const [farmData, setFarmData] = useState([]);
    const [selectedFarm, setSelectedFarm] = useState('all'); 
    const [selectedTime, setSelectedTime] = useState('all');
    const [labels, setLabels] = useState([]);
    const [sdwTypeTableData, setSDWTypeTableData] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'pie',
        },
        colors: [],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom',
                },
            },
        }],
    });

    const token = getToken();
    const farmId = getFarmId();

    const fetchWastePieChartData = async (farm, time) => {
        try {
            const queryParams = new URLSearchParams({ farm });
            if (time && time !== 'all') {
                queryParams.append('time', time);
            }
            const response = await fetch(`${BASE_URL}/api/wastepiechart?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } 
    
            const fetchedData = await response.json();
            console.log({ fetchedData }); 
    
            const labels = fetchedData.strainData.map(item => item.strain_name);
            const series = fetchedData.strainData.map(item => Number(item.total_weight));
    
            // Generate a unique color for each strain
            const colors = chroma.scale('Set2').mode('lch').colors(labels.length);
    
            const sdwTypeTableData = fetchedData.sdwTypeData.map(item => ({
                sdw_type: item.sdw_type,
                total_weight: Number(item.total_weight),
            }));
    
            console.log({ labels, series, colors, sdwTypeTableData }); // Debugging logs
    
            setData(series);
            setLabels(labels);
            setChartOptions(prevOptions => ({
                ...prevOptions,
                labels: labels,
                colors: colors,
            }));
            setSDWTypeTableData(sdwTypeTableData);
        } catch (err) {
            console.error('Error fetching data', err);
        }
    };

    const fetchFarms = () => { 
        fetch(`${BASE_URL}/api/readfarms`, { 
          method: 'GET', 
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json(); // Directly parse as JSON, instead of using response.text()
        })
        .then(data => {
          setFarmData(data);
          // Find the farm_name corresponding to the user's farm_id
          const userFarm = data.find(farm => farm.farm_id === farmId);
          setSelectedFarm(userFarm ? userFarm.farm_name : 'all'); // Default to 'all' if no match
          if (userFarm) {
            fetchWastePieChartData(userFarm.farm_name, selectedTime); // Fetch data for the user's farm initially
          } else {
            fetchWastePieChartData('all', selectedTime); // Fetch data for all farms initially if no match
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }

    useEffect(() => {
        fetchFarms();
    }, []);

    useEffect(() => {
        if (selectedFarm) {
            fetchWastePieChartData(selectedFarm, selectedTime);
        }
    }, [selectedFarm, selectedTime]);

    const handleFarmChange = (event) => {
        setSelectedFarm(event.target.value);
    };

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
    };
    
    return (
        <Paper sx={{ display: 'flex', padding: 2, flexDirection: 'column' }}>

            {/* Title and Filters Container */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                    Total Fresh Waste
                </Typography>
    
                {/* Filters */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                    {/* Farm Filter Dropdown */}
                    <FormControl sx={{ minWidth: 140, mr: 2 }}>
                        <InputLabel id="farm-select-label">Filter farm</InputLabel>
                        <Select
                            labelId="farm-select-label"
                            id="farm-select"
                            value={selectedFarm}
                            label="Farm"
                            onChange={handleFarmChange}
                        >
                            <MenuItem value="all">
                                <em>All</em>
                            </MenuItem>
                            {farmData.map((farm, index) => (
                                <MenuItem key={`${farm.farm_name}-${index}`} value={farm.farm_name}>{farm.farm_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
    
                    {/* Time Filter Dropdown */}
                    <FormControl sx={{ minWidth: 140 }}>
                        <InputLabel id="time-select-label">Filter time</InputLabel>
                        <Select 
                            labelId="time-select-label"
                            id="time-select"
                            value={selectedTime}
                            onChange={handleTimeChange}
                        >
                            <MenuItem value="all"><em>All Time</em></MenuItem>
                            <MenuItem value="24">Last 24 hours</MenuItem>
                            <MenuItem value="48">Last 48 hours</MenuItem>
                            <MenuItem value="3">Last 3 days</MenuItem>
                            <MenuItem value="7">Last 7 days</MenuItem>
                            <MenuItem value="30">Last 30 days</MenuItem>
                            <MenuItem value="month">Last month</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
                    
            {/* Main Content */}
            <Grid container spacing={2}>

                {/* Strain Table */}
                <Grid item xs={12} md={4}>
                    <TableContainer component={Paper} elevation={3}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Strain</TableCell>
                                    <TableCell align="right">Weight (lbs)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {labels.map((label, index) => (
                                    <TableRow key={label}>
                                        <TableCell component="th" scope="row">{label}</TableCell>
                                        <TableCell align="right">{data[index].toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {data.reduce((sum, weight) => sum + weight, 0).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
    
                {/* Pie Chart and SDW Type Summary */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        {/* Pie Chart */}
                        <Grid item xs={12} md={8}>
                            <ReactApexChart
                                options={chartOptions}
                                series={data}
                                type="pie"
                                width="100%"
                            />
                        </Grid>
    
                        {/* SDW Type Summary */}
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Reason
                                </Typography>
                                <Table size="small">
                                    <TableBody>
                                        {sdwTypeTableData.map(({ sdw_type, total_weight }) => (
                                            <TableRow key={sdw_type}>
                                                <TableCell>{sdw_type}</TableCell>
                                                <TableCell align="right">{total_weight}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );

}

export default WastePieChart;