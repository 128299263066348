import React from 'react';
import Paper from '@mui/material/Paper'; 
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';

function InventoryBarChart() {

  const token = getToken();
  const farmId = getFarmId();

  const [farmData, setFarmData] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState(''); 
  const [selectedTime, setSelectedTime] = React.useState('today');

  const [chartData, setChartData] = React.useState({
    categories: [],
    series: [],
  });

    const fetchBarChartData = async () => {

      const farmQueryParam = selectedFarm ? `?farmName=${encodeURIComponent(selectedFarm)}` : ''
      const timeQueryParam = selectedTime ? `&timeFilter=${selectedTime}` : '';

      const response = await fetch(`${BASE_URL}/api/readinventorybarchartdata${farmQueryParam}${timeQueryParam}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const jsonData = await response.json();
      console.log(jsonData); // debug

      // Aggregate data by strain, summing total weights across all farms.
      const strainWeightMap = jsonData.reduce((acc, { strain_name, total_weight }) => {
        if (!acc[strain_name]) {
          acc[strain_name] = 0;
        }
        acc[strain_name] += total_weight;
        return acc;
      }, {});
  
      const categories = Object.keys(strainWeightMap);
      const seriesData = Object.values(strainWeightMap).map(weight => weight);
  
      setChartData({
        categories,
        series: [{
          name: "Total Weight",
          data: seriesData,
        }],
      });
    };

    const fetchFarms = () => { 
      fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Directly parse as JSON, instead of using response.text()
      })
      .then(data => {
        setFarmData(data);
        // Find the farm_name corresponding to the user's farm_id
        const userFarm = data.find(farm => farm.farm_id === farmId);
        setSelectedFarm(userFarm ? userFarm.farm_name : 'all'); // Default to 'all' if no match
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    React.useEffect(() => {
      fetchBarChartData();
      fetchFarms();
    }, []); 

    React.useEffect(() => {
      fetchBarChartData();
    }, [selectedFarm, selectedTime]); 
  
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false, // hide the toolbar 
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: 'Product (lbs)',
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)} lbs`;
        }
      }
    },
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };
  
  return (
    <div>
      <Paper sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
          Current Inventories
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          {/* Farm Filter Dropdown */}
          <FormControl sx={{ minWidth: 140, mr: 2 }}>
            <InputLabel id="farm-select-label">Filter farm</InputLabel>
            <Select
              labelId="farm-select-label"
              id="farm-select"
              value={selectedFarm}
              label="Farm"
              onChange={handleFarmChange}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              <MenuItem value="GTA">
                GTA
              </MenuItem>
              {farmData.map((farm, index) => (
                <MenuItem key={`${farm.farm_name}-${index}`} value={farm.farm_name}>{farm.farm_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Time Filter Dropdown */}
          <FormControl sx={{ minWidth: 140 }}>
            <InputLabel id="time-select-label">Filter time</InputLabel>
            <Select
              labelId="time-select-label"
              id="time-select"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              <MenuItem value="all">
                <em>All Time</em>
              </MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="24">Last 24 hours</MenuItem>
              <MenuItem value="48">Last 48 hours</MenuItem>
              <MenuItem value="3">Last 3 days</MenuItem>
              <MenuItem value="7">Last 7 days</MenuItem>
              <MenuItem value="30">Last 30 days</MenuItem>
              <MenuItem value="month">Last month</MenuItem>
            </Select>
          </FormControl>

        </Box>
      </Paper>

      <Paper sx={{ p: 1, }}>
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          height={350}
          type="bar"
        />
      </Paper>

    </div>
  );
}

export default InventoryBarChart;