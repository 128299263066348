import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';  
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box, FormControl, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import _ from 'lodash';
import dayjs from 'dayjs'; 
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import YieldGroup from '../common/YieldGroup';

const ContentWrapper = styled('div')({
  flex: '1', // To ensure content takes up remaining space
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

function YieldInput() {

  const token = getToken(); 
  const farmId = getFarmId();
  const navigate = useNavigate();

  const [harvestDate, setHarvestDate] = React.useState(dayjs());
  const [strainMappings, setStrainMappings] = React.useState({});
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [yieldGroupRefs, setYieldGroupRefs] = useState([React.createRef()]);
  const [firstStrain, setFirstStrain] = useState(''); 

  const [openIndex, setOpenIndex] = useState(0);

  const [yieldGroups, setYieldGroups] = useState([{ 
    strain: firstStrain, 
    weight: 0,
    blackBag: false,
    cwCheck: false,
  }]);

  //for mappings
  React.useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/mappings`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
  
        const sourceMappings = {};
        data.source.forEach((record) => sourceMappings[record.source_name] = record.source_id);
  
        const strainMappings = {};
        data.strain.forEach((record) => strainMappings[record.strain_name] = record.strain_id);
        setStrainMappings(strainMappings);

        // Update the first strain value
        if (data.strain.length > 0) {
          setFirstStrain(data.strain[0].strain_name);
        }
      } catch (error) {
        console.error("An error occurred while fetching the mappings:", error);
      }
    };
    fetchMappings();
  }, []); 

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);

          // Find the farm that matches the user's farmId
          const userFarm = jsonData.find(farm => farm.farm_id === farmId);

          // If found, set that as the selectedFarm
          if (userFarm) {
            setSelectedFarm(userFarm.farm_id);
          }
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    if (firstStrain) {  // Make sure firstStrain is not null or undefined
      setYieldGroups(prevYieldGroups => {
        const updatedYieldGroups = [...prevYieldGroups];
        updatedYieldGroups[0].strain = firstStrain;
        return updatedYieldGroups;
      });
    }
  }, [firstStrain]); 

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms();
    };
    fetchData();
    console.log(yieldGroups);
  }, []);

  useEffect(() => {
    console.log(yieldGroups);
  }, [yieldGroups]);

  const addYieldGroup = () => { 
    setYieldGroups(yieldGroups => {
      const newYieldGroups = [...yieldGroups, {
        strain: firstStrain, 
        weight: 0,
        blackBag: false,
        cwCheck: false,
      }];
      setYieldGroupRefs(yieldGroupRefs => [...yieldGroupRefs, React.createRef()]);
      return newYieldGroups;
    });
    setOpenIndex(yieldGroups.length);
  }  

  function removeYieldGroup(index) {
    setYieldGroups(yieldGroups.filter((_, i) => i !== index));
  } 

  const handleYieldGroupChange = (index, newYieldGroupState) => {
    setYieldGroups(prevYieldGroups => {
        const newYieldGroups = [...prevYieldGroups]; // Create a copy of the yieldGroups state
        newYieldGroups[index] = newYieldGroupState; // Replace the state at the given index with the new state
        return newYieldGroups; // Set the new state
    });
  }
  
  const handleHarvestDateChange = (date) => {
    setHarvestDate(date);
  };

  const handleSubmit = async () => {
    const farm_id = selectedFarm;  

    const isAnyWeightZero = yieldGroups.some(group => group.weight <= 0);

    if (isAnyWeightZero) {
      alert('Weight must be greater than 0!');
      return; 
    }

    try {
      // Map inputGroups to new objects
      const mappedYieldInputGroups = yieldGroups.map(group => ({
        ...group,
        strain: strainMappings[group.strain],  
        farm: farm_id,
        weight: group.weight,
        // shelf: group.shelf,
        // sterilization: group.sterilization,
        blackBag: group.blackBag,
        cwCheck: group.cwCheck,
        harvestDate: harvestDate.format('YYYY-MM-DD'),
      }));

      console.log({mappedYieldInputGroups});

      const response = await fetch(`${BASE_URL}/api/flushinput`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mappedYieldInputGroups),  // Use the mappedInputGroups
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log(responseData);
      if (responseData.success) {
        navigate('/yieldview');
        toast.success('Success!', {
          autoClose: 2000,  // Auto close after 2 seconds
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the data:", error);
    }
  };
 
  return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
          pt: '6em',

        }}
        
      >
        <CssBaseline />

          <ContentWrapper>

            {/* HARVEST DATE & FARM */}
            <Grid container spacing={1} justifyContent="center">

              {/* HARVEST DATE */}
              <Grid item xs={4} sx={{mb:4, ml: 10}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Harvest date" 
                    value={harvestDate} 
                    closeOnSelect={true}
                    onChange={handleHarvestDateChange}>
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
              </Grid>

              {/* FARM SELECT */}
              <Grid item xs={5} sx={{mb:4}}>
                <FormControl fullWidth sx={{ width: '70%' }}>
                  <InputLabel>Farm</InputLabel>
                  <Select
                    value={selectedFarm}
                    onChange={(e) => setSelectedFarm(e.target.value)}
                  >
                    {farms.map((farm, index) => (
                      <MenuItem key={index} value={farm.farm_id}>  
                        {farm.farm_name}  
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            {/* ACCORDION */}
            {yieldGroups.map((yieldGroup, index) =>
              <Box
                key={index}
                ref={yieldGroupRefs[index]} 
                display="flex"
                alignItems="center"
                marginBottom="1em"
              >
                <YieldGroup 
                  index={index} 
                  firstStrain={firstStrain}
                  state={yieldGroup} 
                  isOpen={index === openIndex}
                  onToggle={() => setOpenIndex(index === openIndex ? -1 : index)}
                  onChange={(index, newYieldGroupState) => handleYieldGroupChange(index, newYieldGroupState)} 
                  highlight={index === yieldGroups.length - 1} 
                />
            
                {/* Remove button */}
                {yieldGroups.length > 1 && (
                  <Button 
                    onClick={() => removeYieldGroup(index)} 
                    variant="outlined"
                    sx={{
                      minWidth: "initial", 
                      width: 40, 
                      height: 40, 
                      borderRadius: "50%", // this is what makes it a circle
                      padding: 0, 
                      display: "flex", 
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">-</Typography>
                  </Button>
                )}
              </Box>
            )}

            {/* Add button */}
            <Button onClick={addYieldGroup} variant="outlined">+Add</Button> 

            {/* Submit button */}
            <Box margin="1em">
                <Button variant="contained" 
                  onClick={handleSubmit} 
                >Submit</Button>
            </Box>

          </ContentWrapper>

      </Box>
  );
}

export default YieldInput;